import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';       
import { useMyState } from "../state";
import { Box } from '@material-ui/core';
import Filter from './busquedaOP/Filter'

function BusquedaOP() {
    const [state, dispatch] = useMyState();
    return (
        <Box width="100%" height="100%">
            <Box width="100%" height="20%">
                <Paper>
                    <Filter />
                </Paper>
            </Box>
            <Box width="100%" height="80%">
                <Paper height="100%">
                    <p>panel OT result</p>
                </Paper>
            </Box>
        </Box>
    )
}
export default  BusquedaOP