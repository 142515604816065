import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Paper, Button, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const myStyle = makeStyles(
    {
        root: {
            padding : '5px',
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        }
    }

)


const ExpandedPanel = ({children})=>{
    const myClasses = myStyle
    const [expanded, setExpanded] = React.useState(true)
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
      return (
        <ExpansionPanel expanded={expanded} onChange={handleChange('panel1')}>
            <ExpansionPanelSummary className={myClasses.root}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
            <Typography variant="h4">Filtro</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
      )      
}

function filterDate(name){
    return (
        <Grid item >
            <Grid  container xs="auto" direction="row"  alignItems="flex-start"  spacing={1} wrap="wrap">
                <Grid item xs="auto">
                    <TextField id={name + "desde"}  type="date" label={name + " desde"} variant="outlined" InputLabelProps={{shrink: true}} size="small"/>
                </Grid>
                <Grid item xs="auto">
                    <TextField id={name + "hasta"}  type="date" label="hasta" variant="outlined"InputLabelProps={{shrink: true}} size="small"/>
                </Grid>
            </Grid>    
        </Grid>
    )
}

function standardField(name){
    return (
    <Grid item xs={12}>
        <TextField id={name} label={name}  color="primary" variant="outlined" InputLabelProps={{shrink: true}} size="small"/>
    </Grid>   
    )
}

function Filter(){
    return( 
        <ExpandedPanel>
            <Grid container xs={12} direction="row" justify="flex-start" alignContent="flex-start"  alignItems="stretch" spacing={3}>
                <Grid item xs="auto" alignItems="stretch">
                    <Paper>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            {standardField("Instalacion")}
                            {standardField("Mantenedora")}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs="auto" alignItems="stretch">
                    <Paper>
                        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                            {standardField("Tipo de Operación")}
                            {standardField("Tipo de equipo")}
                            {standardField("equipo")}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs="auto" alignItems="stretch">
                    <Paper >
                        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                            {filterDate("Caducidad")}
                            {filterDate("ÚLtima Operación")}
                        </Grid>
                    </Paper>
                </Grid>                
                <Grid container direction="column" justify="flex-end" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12} alignItems="stretch" >
                        <Button variant="contained" color="primary"><Typography>Filtrar</Typography></Button>
                    </Grid>
                </Grid>                                      
            </Grid>
        </ExpandedPanel>
    )
}

export default Filter