import { useMyState } from "../state";

const API_URL = process.env.REACT_APP_API_URL || "https://localhost:8000/api" ;

class BaseServices{
  
    constructor(apiToken) {
        this.API_URL = API_URL
        this.SEARCH = "/search"
        this.config = {
            headers: {
                'Authorization': 'Bearer ' + apiToken
            }
        } 
    } 

} 

export default BaseServices;