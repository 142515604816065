import React, { useState, useEffect } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import {IconButton, Grid,InputAdornment, Typography, Button, ListItem, Avatar, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useMyState } from "../state";
import SociosServices from '../services/SociosServices';
import SocioRender from './blocks/SocioRender';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';




const useStyles = makeStyles(theme => ({
  root: {
      background: fade(theme.palette.common.white, 0.15),
    },
    margin: {
      margin: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '20ch',
        },
      },
  }));

export default function Socios() {
  const [myState, myDispatch] = useMyState()
  const [sociosList, setSociosList] = useState([])
  const [quickCriteria, setQuickCriteria] = useState(null)
  let sociosService = null
  const handerClick = () => {
    // update socios list
      const filter = { quickCriteria: quickCriteria, limit: 100, init : 0 }
      sociosService.getSociosList(filter, (resp) => { setSociosList(resp.data) } )
  }
  const handleChange = (event) => {
      setQuickCriteria(event.target.value)
  }
    
  useEffect(() => {
    console.log(myState.apiToken);
    sociosService = new SociosServices(myState.apiToken)
  })


  const classes = useStyles();
  return (
      <div>
          <div>
              <Grid container direction="colum">
                  <Grid item>
                      <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                              inputProps={{ 'aria-label': 'search' }}
                              value={quickCriteria} onChange={handleChange}
                          />
                          <IconButton color="primary" aria-label="buscar" onClick={handerClick} >
                          <SearchIcon />
                      </IconButton>
                    </div>
                  </Grid>
              </Grid>
          </div>

          <Grid container spacing={3} direction="row" justify="flex-start" alignItems="flex-start">
          {sociosList.map(socio => {
              return (
                  <Grid item spacing={2}>
                      <SocioRender socio={socio} /> 
                  </Grid>
              )
          })
          }
          </Grid>

      </div>
  )
} 