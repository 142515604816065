//react
import React from 'react';
//Materials
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//app
import { useMyState } from "../state";
import Tools from "./Tools";
import BusquedaOP from '../components/BusquedaOP'
import BusquedaOT from '../components/BusquedaOT'
import Socios from '../components/Socios'
import Forja from '../components/Forja'
import Default from '../components/Default'
import { Box } from '@material-ui/core';

const components = {
    '/': Default,
    'Socios': Socios,
    'Busqueda OP': BusquedaOP ,
    'Busqueda OT': BusquedaOT,
    'Forja': Forja
}


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

function Content() {
    const [state, dispatch] = useMyState();
    const classes = useStyles();

    function FormRow() {
        return (
          <React.Fragment>
            <Grid item xs={4}>
              <Paper className={classes.paper}>item</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>item</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>item</Paper>
            </Grid>
          </React.Fragment>
        );
      }

    return (
        <Box display="flex" flexDirection="row" height="100%" width ="100%">
            <box >
                <Tools />
            </box>
            <box>
                {React.createElement(components[state.drawlerMenuSelected])}
            </box>
        </Box>

        //
        //<Box height="100%">
        //    {React.createElement(components[state.drawlerMenuSelected])}
        // </Box>
    )
}
export default  Content

