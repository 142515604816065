import BaseServices from "./BaseService";
import axios from 'axios';


class SociosServices extends BaseServices{
        
    constructor(apiToken){
      super(apiToken)
      console.log (apiToken)
      this.getSociosList=this.getSociosList.bind(this)
      this.FULL_URL = this.API_URL + '/' + 'socios'
      this.FULL_URL_PER = this.API_URL + '/' + 'personas'
      console.log(this.config)
    } 

  getSociosList(filter, handler) {
    let sociosFilter = { quickCriteria: filter.quickCriteria, limit: filter.limit, init : filter.init }
      axios.post(this.FULL_URL_PER + this.SEARCH,sociosFilter, this.config)
      .then(res => {
        console.log(res);
        handler(res.data)
      })
    }
} 


export default SociosServices;