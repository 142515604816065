import React, { useState , useEffect }from 'react';
      
//material
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Avatar , Tooltip} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useAuth0 } from "@auth0/auth0-react";
import { useMyState }   from "../state";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));



function Login () { 
    const [myState, myDispatch] = useMyState()
    const { loginWithRedirect, logout, user, isAuthenticated, isLoading,  getAccessTokenSilently } = useAuth0();
    const[token, setToken] = useState(null)

    useEffect( () => {
        const setApiToken = async () => {
            console.log("request apitoken")
            let apiToken = await getAccessTokenSilently();
            setToken(apiToken);
            console.log (apiToken)
            myDispatch( { type: "setToken", data: apiToken })
        }
        if (isAuthenticated && !token) {
            setApiToken();    
        } 
    })

    const doLogout= () =>{
        logout({ returnTo: window.location.origin })
        myDispatch(myState, { type: "setToken", data: null })
    } 
 
    const noLogged = () => {
        return (
            <div>
                <Button variant="outlined" color="inherit" onClick ={loginWithRedirect}>login</Button>
            </div>
        )
    }
    
    const justLogged = () => {
        return (
           <box color="primary.main" display="flex" direction="row" justify="flex-end" alignItems="center">
                <Grid container > 
                    <Grid item xs={12} sm={4} md={4} >
                        <Typography >Bienvenido: </Typography>
                        <Tooltip title={token ? token:''} aria-label="{token ? token:''}">
                            <Typography>{user.nickname}</Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Avatar alt={user.nickname} src={user.picture} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>                    
                        <Button onClick ={doLogout} variant="outlined"  color="inherit" >logout</Button>
                    </Grid>
                </Grid>

            </box>  
        )
    }
    if (isAuthenticated) {
        return (justLogged())  
    } 
    else
        if (isLoading) {
            return <div>loading....</div>
        } else {
            return (noLogged())      
        } 
}


export default Login