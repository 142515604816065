import React, { createContext, useContext, useReducer } from "react";

export const StateContext = createContext();


const initialState = {
  counter: 0,
  drawler: true,
  drawlerMenuSelected: "/",
  apiToken: null
}

const reducer = (state, action) => {
  switch (action.type) {
      case "drawer":
        return {
            ...state,
            drawler: action.data
        };
        case "drawlerMenuSelected":
          return {
              ...state,
              drawlerMenuSelected: action.data
          };
          case "setToken":
            return {
                ...state,
                apiToken: action.data
            };
     default:
        return state;
     }
};


export const StateProvider = ({ children }) => {
  const myReducer = reducer
  const myIntialState = initialState

  return (
    <StateContext.Provider value={useReducer(myReducer, myIntialState)}>
      {children}
    </StateContext.Provider>
  )
}

export const useMyState = () => useContext(StateContext);


