import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { AccessAlarm, PeopleAlt } from '@material-ui/icons';

import { useMyState } from "../state";

const options = [
    { text: "Socios", icon: PeopleAlt },
    { text: "Busqueda OP" , icon: AccessAlarm },
    { text: "Busqueda OT" , icon: AccessAlarm },
    { text:  "Forja", icon: AccessAlarm }
]
const Tools = () => {
    const [state, dispatch] = useMyState();
    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        dispatch({type:'drawer', data: open});
      };
    
    return (
        <List>
            {options.map((item, index) => (
                <ListItem button key={item.text} onClick= {()=>{dispatch({type:'drawlerMenuSelected', data: item.text});}} >
                <ListItemIcon>
                    {React.createElement(item.icon)}
                </ListItemIcon>        
            </ListItem>
            ))}
        </List>
    )
}
export default Tools;