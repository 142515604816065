import React from 'react';
import './App.css';
import { StateProvider } from "./state"
import { Auth0Provider } from "@auth0/auth0-react";
import NavBar from './layout/NavBar'
import Content from './layout/Content'
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';


function App() {
  const domain = process.env.REACT_APP_DOMAIN || 'dev--ik9a2vc.eu.auth0.com'
  const clienteId = process.env.REACT_APP_CLIENT_ID || 'YJqT8B4SFDK4J06ojfaiAHHiVAVUrJBL'
  const audience = process.env.REACT_APP_AUDIENCE || 'apbbk.asociacionparqueflores.es'
  return (
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={domain } 
        clientId={clienteId} 
        audience ={audience} 
        redirectUri={window.location.origin}
      >
      <StateProvider>
        <div className="App">
          <div>
            <NavBar />
          </div>
            <Content />
        </div>
        </StateProvider>
      </Auth0Provider>
    </ThemeProvider>
  );
}

export default App;
