import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function Default()
{
    return (
        <div>
            <Typography>default</Typography>
        </div>
    )
}