//React
import React from 'react'

//material
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Drawler from './Drawler'
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';



//app
import { useMyState } from "../state"
import Login from "../components/Login"


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

const NavBar = () => {
    const [state, dispatch] = useMyState();
    const classes = useStyles();
    return(
        <div className={classes.root} width={1/4}>
            <AppBar position="static">
                <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    News
                </Typography>
                <Login />   
                </Toolbar>
            </AppBar>
        </div>        
    )
}
export default NavBar;