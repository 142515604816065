import React from 'react'
import { Typography, Grid, Paper, Card, CardActions, CardContent, Button, Avatar, CardHeader, Collapse, IconButton } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
        backgroundColor: theme.palette.primary[200]
    },
    CardHeader: {
        backgroundColor: theme.palette.secondary[800]
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

let SocioRender=(props) => {
    const [expanded, setExpanded] = React.useState(false);
    const classes = useStyles();
    const persona = props.socio;
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const fields = [
        {data: persona.email, name: "EMAIL" },
        {data: persona.movil & " | " & persona.movil2 & " | " & persona.telefono, name: "TLFS" },
        {data: persona.created_at & " | " & persona.updated_at, name: "TCREADO EL | F. ULT. MODF." },
    ]
    return (
        <Card className={classes.root}>
            <CardHeader className={classes.CardHeader} 
                avatar={
                    <Avatar>{persona.nombre.charAt(0).toUpperCase()}{persona.apellidos.charAt(0).toUpperCase()}</Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={"".concat(persona.nombre).concat(", ").concat(persona.apellidos)}
                subheader={"num-socio"} 
            />
            <CardActions>
                <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="mas">
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} >
            <Typography variant="overline" align="left">Datos adicionales....:</Typography>
                <Paper>
                {
                    fields.map((item, key) => {
                        if (item.data != null) {
                            console.log(item.name)
                            return (
                                <div >
                                    <Typography variant="caption" align="left" alignItems="left" color="textSecondary">{item.name} </Typography>
                                    <Typography variant="body1" align="left">{item.data}</Typography>
                                </div>
                            )
                        }
                    })
                }

                </Paper>
            </Collapse>
        </Card>
    )
}

export default SocioRender = SocioRender;